import { create } from 'zustand'

import { AuthSlice, useAuthSlice } from '@/store/auth'

import { SidebarSlice, useSidebarSlice } from './sidebar'

type Store = SidebarSlice & AuthSlice

const useStore = create<Store>()((...params) => ({
  ...useSidebarSlice(...params),
  ...useAuthSlice(...params)
}))

export { useStore }

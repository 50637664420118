import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'sonner'

import { TanstackProvider } from '@/providers/TanstackQuery'
import { router } from '@/routes'

export function App() {
  return (
    <>
      <TanstackProvider>
        <Toaster
          position="top-right"
          richColors
          toastOptions={{
            style: {
              padding: '1rem'
            }
          }}
        />
        <RouterProvider router={router} />
      </TanstackProvider>
    </>
  )
}

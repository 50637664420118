/* eslint-disable tailwindcss/no-custom-classname */
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ChevronDown, Dot } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Icon, IconsType } from '@/components/ui/icon'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { Submenu } from '@/utils/menu-list.utils'
import { DropdownMenuArrow } from '@radix-ui/react-dropdown-menu'

interface CollapseMenuButtonProps {
  icon: IconsType
  label: string
  active: boolean
  submenus: Submenu[]
  isOpen: boolean | undefined
}

export function CollapseMenuButton({
  icon,
  label,
  active,
  submenus,
  isOpen
}: CollapseMenuButtonProps) {
  const isSubmenuActive = submenus.some((submenu) => submenu.active)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isSubmenuActive)

  return isOpen ? (
    <Collapsible open={isCollapsed} onOpenChange={setIsCollapsed} className="w-full">
      <CollapsibleTrigger
        className="mb-1 [&[data-state=open]>div>div>svg]:rotate-180"
        asChild
      >
        <Button
          variant={active ? 'default' : 'ghost'}
          className="h-10 w-full justify-start"
        >
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center">
              <Icon name={icon} className="mr-4" />
              <p
                className={cn(
                  'max-w-[150px] truncate',
                  isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0'
                )}
              >
                {label}
              </p>
            </div>
            <div
              className={cn(
                'whitespace-nowrap',
                isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0'
              )}
            >
              <ChevronDown size={18} className="transition-transform duration-300" />
            </div>
          </div>
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down overflow-hidden">
        {submenus.map(({ href, label, active, disabled }, index) => (
          <Button
            key={index}
            variant={disabled ? 'none' : 'sidebar-item'}
            className={cn(
              'mb-1 h-10 w-full justify-start',
              active && 'text-orange-900',
              disabled && 'cursor-not-allowed'
            )}
            disabled={disabled}
            asChild
          >
            {!disabled ? (
              <Link
                to={href}
                className={cn(isOpen ? '' : 'flex items-center justify-center')}
              >
                <span className="ml-2 mr-4">
                  <Dot size={18} />
                </span>
                <p
                  className={cn(
                    'max-w-[170px] truncate',
                    isOpen ? 'opacity-100' : '-translate-x-96 opacity-0'
                  )}
                >
                  {label}
                </p>
              </Link>
            ) : (
              <div className={cn(isOpen ? '' : 'flex items-center justify-center')}>
                <span className="ml-2 mr-4">
                  <Dot size={18} />
                </span>
                <p
                  className={cn(
                    'max-w-[170px] truncate',
                    isOpen ? 'opacity-100' : '-translate-x-96 opacity-0'
                  )}
                >
                  {label}
                </p>
              </div>
            )}
          </Button>
        ))}
      </CollapsibleContent>
    </Collapsible>
  ) : (
    <DropdownMenu>
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button
                variant={active ? 'secondary' : 'ghost'}
                className="mb-1 h-10 w-full justify-start"
              >
                <div className="flex w-full items-center justify-center">
                  <Icon name={icon} size={16} />
                  <p
                    className={cn(
                      'max-w-[200px] truncate',
                      isOpen === false ? 'opacity-0' : 'opacity-100'
                    )}
                  >
                    {label}
                  </p>
                </div>
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <TooltipContent side="right" align="start" alignOffset={2}>
            {label}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DropdownMenuContent side="right" sideOffset={25} align="start">
        <DropdownMenuLabel className="max-w-[190px] truncate">{label}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {submenus.map(({ href, label }, index) => (
          <DropdownMenuItem key={index} asChild>
            <Link to={href}>
              <p className="max-w-[180px] truncate">{label}</p>
            </Link>
          </DropdownMenuItem>
        ))}
        <DropdownMenuArrow className="fill-border" />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

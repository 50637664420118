import { lazy, Suspense } from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { Loading } from '@/components/structure/loading'
import { APP_ROUTES } from '@/constants/app-routes'
import { AppLayout } from '@/pages/_layouts/app'
import { AuthLayout } from '@/pages/_layouts/auth'
import { ExternalLayout } from '@/pages/_layouts/external'

const UsersPage = lazy(() => import('./pages/app/users/page'))
const SaveUserPage = lazy(() => import('./pages/app/users/save/page'))
const ProfileUserPage = lazy(() => import('./pages/app/users/details/page'))

const DashboardPage = lazy(() => import('./pages/app/dashboard/page'))

const SignInPage = lazy(() => import('./pages/_layouts/auth/sign-in/page'))

const NotFoundPage = lazy(() => import('./pages/404'))
const ErrorPage = lazy(() => import('./pages/error'))

// const SaveProjectProposalPage = lazy(
//   () => import('./pages/app/projects/proposals/save/page')
// )
const ProjectsProposalsPage = lazy(() => import('./pages/app/projects/proposals/page'))

const SaveEventPage = lazy(() => import('./pages/app/events/save/page'))
const EventsPage = lazy(() => import('./pages/app/events/page'))

const ExternalEventsPage = lazy(() => import('./pages/external/events/page'))
const ExternalUserProfilePage = lazy(
  () => import('./pages/external/external-user/profile/page')
)
const EventRegistrationPage = lazy(
  () => import('./pages/external/events/registration/page')
)

const EventDetailsPage = lazy(() => import('./pages/external/events/details/page'))

const CreateExternalUserPage = lazy(
  () => import('./pages/external/external-user/create/page')
)

export const router = createBrowserRouter([
  {
    path: APP_ROUTES.ROOT,
    errorElement: (
      <Suspense fallback={<Loading />}>
        <ErrorPage />
      </Suspense>
    ),
    element: <AppLayout />,
    children: [
      {
        path: APP_ROUTES.ROOT,
        element: (
          <Suspense fallback={<Loading />}>
            <DashboardPage />
          </Suspense>
        )
      },
      {
        path: APP_ROUTES.USERS,
        element: (
          <Suspense fallback={<Loading />}>
            <UsersPage />
          </Suspense>
        )
      },
      {
        path: APP_ROUTES.CREATE_USER,
        element: (
          <Suspense fallback={<Loading />}>
            <SaveUserPage />
          </Suspense>
        )
      },
      {
        path: APP_ROUTES.EDIT_USER + ':userId',
        element: (
          <Suspense fallback={<Loading />}>
            <SaveUserPage />
          </Suspense>
        )
      },
      {
        path: APP_ROUTES.USER_PROFILE + ':userId',
        element: (
          <Suspense fallback={<Loading />}>
            <ProfileUserPage />
          </Suspense>
        )
      },
      {
        path: APP_ROUTES.PROJECTS_PROPOSALS,
        element: (
          <Suspense fallback={<Loading />}>
            <ProjectsProposalsPage />
          </Suspense>
        )
      },
      {
        path: APP_ROUTES.CREATE_PROJECT_PROPOSAL,
        element: (
          <Suspense fallback={<Loading />}>{/* <SaveProjectProposalPage /> */}</Suspense>
        )
      },
      {
        path: APP_ROUTES.EDIT_PROJECT_PROPOSAL + ':projectProposalId',
        element: (
          <Suspense fallback={<Loading />}>{/* <SaveProjectProposalPage /> */}</Suspense>
        )
      },
      {
        path: APP_ROUTES.EVENTS,
        element: (
          <Suspense fallback={<Loading />}>
            <EventsPage />
          </Suspense>
        )
      },
      {
        path: APP_ROUTES.CREATE_EVENT,
        element: (
          <Suspense fallback={<Loading />}>
            <SaveEventPage />
          </Suspense>
        )
      },
      {
        path: APP_ROUTES.EDIT_EVENT + ':eventId',
        element: (
          <Suspense fallback={<Loading />}>
            <SaveEventPage />
          </Suspense>
        )
      }
    ]
  },
  {
    path: `${APP_ROUTES.ROOT}/common/`,
    errorElement: (
      <Suspense fallback={<Loading />}>
        <ErrorPage />
      </Suspense>
    ),
    element: <ExternalLayout />,
    children: [
      {
        path: `/common/${APP_ROUTES.EVENTS}`,
        element: (
          <Suspense fallback={<Loading />}>
            <ExternalEventsPage />
          </Suspense>
        )
      },
      {
        path: `/common/${APP_ROUTES.EVENT_REGISTRATION}`,
        element: (
          <Suspense fallback={<Loading />}>
            <EventRegistrationPage />
          </Suspense>
        )
      },
      {
        path: `/common/${APP_ROUTES.EVENT_DETAILS}`,
        element: (
          <Suspense fallback={<Loading />}>
            <EventDetailsPage />
          </Suspense>
        )
      },
      {
        path: `/common/profile`,
        element: (
          <Suspense fallback={<Loading />}>
            <ExternalUserProfilePage />
          </Suspense>
        )
      }
    ]
  },
  {
    path: `${APP_ROUTES.ROOT}/common/`,
    errorElement: (
      <Suspense fallback={<Loading />}>
        <ErrorPage />
      </Suspense>
    ),
    element: <ExternalLayout hideRegisterButtons />,
    children: [
      {
        path: `/common/${APP_ROUTES.CREATE_EXTERNAL_USER}`,
        element: (
          <Suspense fallback={<Loading />}>
            <CreateExternalUserPage />
          </Suspense>
        )
      }
    ]
  },
  {
    path: APP_ROUTES.ROOT,
    errorElement: (
      <Suspense fallback={<Loading />}>
        <ErrorPage />
      </Suspense>
    ),
    element: <AuthLayout />,
    children: [
      {
        path: APP_ROUTES.SIGN_IN,
        element: (
          <Suspense fallback={<Loading />}>
            <SignInPage />
          </Suspense>
        )
      }
    ]
  },
  {
    path: APP_ROUTES.NOT_FOUND,
    element: (
      <Suspense fallback={<Loading />}>
        <NotFoundPage />
      </Suspense>
    )
  }
])

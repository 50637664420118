import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { cn } from '@/lib/utils'
import { useStore } from '@/store'

export interface FooterProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isLoginPage?: boolean
}

export function Footer({ isLoginPage }: FooterProps) {
  const { pathname } = useLocation()

  const { isOpen, isAuthenticated } = useStore((state) => {
    return {
      isOpen: state.isOpen,
      isAuthenticated: state.isAuthenticated
    }
  })

  const margin =
    !isAuthenticated || pathname.includes('common')
      ? '0'
      : isOpen
        ? 'lg:ml-72'
        : 'lg:ml-[90px]'

  return (
    <footer
      className={cn(
        'max-h-[60px] transition-[margin-left] duration-300 ease-in-out',
        margin
      )}
    >
      <div
        className={cn(
          'z-20 flex w-full items-center gap-2 border-t bg-background px-6 py-4',
          isLoginPage ? 'justify-start' : 'justify-end'
        )}
      >
        <Link to="https://funpec.br/" target="_blank">
          <p className="text-sm font-bold leading-6 text-orange-900">
            &copy; FUNPEC 2024.
          </p>
        </Link>

        <p className="text-sm font-bold leading-6 text-muted-foreground">
          Todos os direitos reservados.
        </p>
      </div>
    </footer>
  )
}

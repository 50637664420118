import { Icon } from '@/components/ui/icon'
import { cn } from '@/lib/utils'

export function Loading({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      // eslint-disable-next-line tailwindcss/classnames-order
      className={cn(
        'inset-0 flex animate-blink flex-col items-center justify-center gap-5 p-16',
        className
      )}
      {...props}
    >
      <Icon name="satisfied" size={120} />
      <p className="text-2xl font-semibold text-orange-900">Carregando...</p>
    </div>
  )
}

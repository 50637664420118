import { IconsType } from '@/components/ui/icon'
import { APP_ROUTES } from '@/constants/app-routes'

export type Submenu = {
  href: string
  label: string
  active: boolean
  disabled?: boolean
}

type Menu = {
  href: string
  label: string
  active: boolean
  icon: IconsType
  submenus: Submenu[]
}

type Group = {
  groupLabel: string
  menus: Menu[]
}

export function getMenuList(pathname: string): Group[] {
  return [
    {
      groupLabel: '',
      menus: [
        {
          href: APP_ROUTES.ROOT,
          label: 'Início',
          active: pathname.includes('/home'),
          icon: 'home',
          submenus: []
        }
      ]
    },
    {
      groupLabel: 'Gestão',
      menus: [
        {
          href: '/users',
          label: 'Usuários',
          active: pathname.includes('/users'),
          icon: 'lock_person',
          submenus: []
        },
        {
          href: '/projects',
          label: 'Projetos',
          active: pathname.includes('/projects'),
          icon: 'lightbulb',
          submenus: [
            {
              href: APP_ROUTES.PROJECTS_PROPOSALS,
              label: 'Cadastrados',
              active: pathname === APP_ROUTES.PROJECTS,
              disabled: true
            },
            {
              href: APP_ROUTES.PROJECTS_PROPOSALS,
              label: 'Propostas de projetos',
              active: pathname === APP_ROUTES.PROJECTS_PROPOSALS
            }
          ]
        },
        {
          href: '/events',
          label: 'Eventos',
          active: pathname.includes('/events'),
          icon: 'local_activity',
          submenus: []
        }
      ]
    }
  ]
}

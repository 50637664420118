import { useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Icon, IconsType } from '@/components/ui/icon'
import { APP_ROUTES } from '@/constants/app-routes'
import { cn } from '@/lib/utils'
import { useStore } from '@/store'
import { getInitials } from '@/utils/get-initials.utils'

import { UserNav } from '../navbar/user-nav'

const headerLinks = [
  {
    title: 'Início',
    icon: 'home',
    pathname: '/common' + APP_ROUTES.EVENTS
  }
]

interface HeaderProps {
  hiddenRegisterButtons?: boolean
}

export function Header({ hiddenRegisterButtons }: HeaderProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { logged } = useStore((store) => {
    return { logout: store.logout, logged: store.userLogged }
  })

  const userLogged = useMemo(() => {
    if (logged) {
      return {
        sub: logged.sub,
        email: logged.email,
        initials: getInitials(logged.sub as string)
      }
    }
    return null
  }, [logged])

  function handleLogin() {
    navigate(APP_ROUTES.SIGN_IN)
  }

  function handleRegister() {
    navigate(`/common${APP_ROUTES.CREATE_EXTERNAL_USER}`)
  }

  return (
    <header className="border-b border-gray-dark-100">
      <div className="mx-auto flex h-14 w-full max-w-[1072px] items-center justify-between px-8 py-3">
        <div className="flex max-h-6 gap-x-6">
          <Link to={'/common' + APP_ROUTES.EVENTS}>
            <img
              src="/images/large-logo.png"
              alt="Logo"
              className="max-sm:hidden"
              width={95}
              height={24}
            />
            <img
              src="/images/small-logo.png"
              alt="Logo"
              className="sm:hidden"
              width={16}
              height={24}
            />
          </Link>

          <nav className="flex items-center">
            <ul className="flex items-center gap-x-2">
              {headerLinks.map((link) => (
                <li key={link.title} className="flex items-center">
                  <Button variant="invisible" asChild className="p-0">
                    <Link to={link.pathname} className="flex gap-x-[6px]">
                      <Icon
                        name={link.icon as IconsType}
                        size={20}
                        className={cn('text-gray-dark-900', {
                          'text-orange-900': pathname === link.pathname
                        })}
                      />

                      <span
                        className={cn('font-bold text-gray-dark-900 max-sm:hidden', {
                          'text-orange-900': pathname === link.pathname
                        })}
                      >
                        Início
                      </span>
                    </Link>
                  </Button>
                </li>
              ))}
            </ul>
          </nav>

          <div className="flex items-center gap-x-[6px]">
            <Icon name="world" size={20} className="text-gray-dark-900" />
            <span className="text-sm font-semibold text-gray-dark-900">BR</span>
            <Icon name="chevron_right" size={20} className="rotate-90 text-orange-900" />
          </div>
        </div>

        <div
          className={cn('flex items-center gap-x-2', {
            hidden: hiddenRegisterButtons
          })}
        >
          {userLogged ? (
            <UserNav />
          ) : (
            <>
              <Button
                variant={'outline'}
                onClick={handleLogin}
                className="rounded-[100px] border-orange-900 font-bold max-sm:hidden"
              >
                Fazer login
              </Button>

              <Button
                variant={'default'}
                className="rounded-[100px] font-bold"
                onClick={handleRegister}
              >
                Cadastrar-se
              </Button>

              <Button variant={'invisible'} onClick={handleLogin} className="sm:hidden">
                <Icon name="login" size={20} className="text-orange-900" />
              </Button>
            </>
          )}
        </div>
      </div>
    </header>
  )
}
